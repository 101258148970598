<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup class="">
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign in to your account</p>
                  <CInput
                    v-model="userInfo.email"
                    placeholder="Username"
                    autocomplete="username email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                  v-model="userInfo.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" @click="login()"  class="px-4">Login</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              style="background:Black"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              
            >
              <CCardBody class="vertical-center">
                <img src="@/assets/img/FC Logo_white.png" alt="" width="100%">
               
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Vue from 'vue';
import {getFieldById, Login} from "../../assets/js/service"
export default {
  name: 'Login',
  data() {
    return {
      userInfo:{
        email:null,
        password:null,
      }
    }
  },
  beforeCreate() {
    let token = localStorage.getItem('token');
    let id = localStorage.getItem('userId');
    if(token){
      getFieldById('Users',id,token).then(resp=>{
        this.$router.push('home');
      })
    }
  },
  mounted() {
    
  },
  methods: {
    login(){
      Login(this.userInfo).then(resp=>{
        localStorage.setItem('token',resp.data.id);
        localStorage.setItem('userId',resp.data.userId);
        localStorage.setItem('v_$@21jh0#3','d9c64cc9ef89ffd257fc7678d3088077');
         Vue.$toast.success("Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,

            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        this.$router.push('home');
      })
    }
  },
}
</script>
